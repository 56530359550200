import { Avatar, Box, Button, IconButton, Typography, styled } from "@mui/material"
import { Link } from "react-router-dom";
import ReplyIcon from '@mui/icons-material/ReplyRounded';
import FollowIcon from '@mui/icons-material/FavoriteBorder';
import TurnRightIcon from '@mui/icons-material/TurnRightRounded';
import TokenLogo from "../tokenLogo"
import threadIcon from "../../assets/images/thread.svg";
import { CreatorAvatar, CreatorName, UserAvatar, UserName } from "./user";

const ChatBox = styled(Box)`
    background: #00000030;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    padding: 1em;
`

export default function Chat({ data, token, repliedTo, onReply }: { data?: any, token?: any, repliedTo?: number, onReply?: () => void }) {
    return (
        <ChatBox id={`thread-${String(data?.id).padStart(6, '0')}`}>
            {
                data
                    ? <>
                        {
                            data.code && data.code.includes('#') &&
                            <TurnRightIcon sx={{ color: "white", width: 18, transform: 'scaleY(-1)', opacity: 0.5, pr: '3px', pl: `${data.code ? (data.code.split('#').length - 2) * 24 - 11 : 0}px` }} />
                        }
                        <UserAvatar user={data.user} address={data.replyAddress} />
                        <Box display="flex" flexDirection="column" gap="1em" flex="1">
                            <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
                                <Box display="flex">
                                    <UserName user={data.user} address={data.replyAddress} />
                                    {
                                        !!token && token?.creatorAddress === data.replyAddress &&
                                        <>
                                            <Typography color="#9E9E9E" fontSize={14}>—</Typography>
                                            <Typography color="#9E9E9E" fontSize={14}>dev</Typography>
                                        </>
                                    }
                                </Box>
                                {
                                    !!repliedTo &&
                                    <Typography color="#9E9E9E" fontSize={14} ml={{ xs: "auto", sm: 3 }}>Re: #{String(repliedTo).padStart(6, '0')}</Typography>
                                }
                                <Typography color="#9E9E9E" fontSize={14} ml="auto">{new Date(data.date).toLocaleString('en-US', { month: 'short', year: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false })}</Typography>
                            </Box>
                            <div style={{ color: 'white', whiteSpace: 'break-spaces', overflowWrap: 'anywhere' }}>{data.comment}</div>
                            <Box display="flex" gap="2px" alignItems="center" sx={{ color: "#9E9E9E" }}>
                                {
                                    token
                                    ?   <>
                                            {/* <IconButton>
                                                <FollowIcon color={"warning"} />
                                            </IconButton>
                                            <sub style={{ marginLeft: -8, marginTop: 8, color: "" }}>24</sub>
                                            <div style={{ width: 4, height: 4, margin: '0 0.5em', background: "gray", borderRadius: 6 }} /> */}
                                            {
                                                !!onReply &&
                                                <Button variant="text" color="inherit" onClick={onReply}>
                                                    <ReplyIcon />
                                                    Reply #{String(data.id).padStart(6, '0')}
                                                </Button>
                                            }
                                        </>
                                    :   <>
                                            <Link to={`/${data.network}/${data.tokenAddress}#thread-${String(data.id).padStart(6, '0')}`} style={{ textDecoration: 'none', color: '#E12D85', fontSize: 14, display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                View thread
                                                <img src={threadIcon} />
                                            </Link>
                                        </>
                                }
                            </Box>
                        </Box>
                    </>
                    : <>
                        <CreatorAvatar token={token} />
                        <Box display="flex" flexDirection="column" gap="1em" flex="1">
                            <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
                                <Box display="flex">
                                    <CreatorName token={token} />
                                    <Typography color="#9E9E9E" fontSize={14}>—</Typography>
                                    <Typography color="#9E9E9E" fontSize={14}>dev</Typography>
                                </Box>
                                <Typography color="#9E9E9E" fontSize={14} ml="auto">{new Date(token?.creationTime).toLocaleString('en-US', { month: 'short', year: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false })}</Typography>
                            </Box>
                            <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap="16px">
                                <TokenLogo logo={token?.tokenImage} size="100px" />
                                <div style={{ fontFamily: "Inter", display: "flex", flexDirection: "column", gap: "1em" }}>
                                    <Typography color="white" fontSize="20px" fontFamily="Inter">{token?.tokenName}</Typography>
                                    <Box display="flex" mt="-1rem">
                                        <Typography color="#9E9E9E" fontSize={14}>Ticker:</Typography>
                                        <Typography color="#D9D9D9" fontSize={14}>{token?.tokenSymbol}</Typography>
                                    </Box>
                                    <div style={{ color: 'white' }}>{token?.tokenDescription}</div>
                                </div>
                            </Box>
                        </Box>
                    </>
            }
        </ChatBox>
    )
}