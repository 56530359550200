import MenuIcon from "@mui/icons-material/Menu";
import {
    AppBar,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputBase,
    InputLabel,
    Toolbar,
    Typography,
    styled,
    useScrollTrigger,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {
    useDisconnect,
    useWeb3Modal,
    useWeb3ModalAccount,
    useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Logo from "../../assets/images/logo.png";
// import ETHIcon from '../../assets/images/coin/eth.png';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import { LinearGradient } from "@amcharts/amcharts5";
// import FaceIcon from '@mui/icons-material/Face';
import copy from "copy-to-clipboard";
import axios from "axios";
import { BrowserProvider, Eip1193Provider, ethers } from "ethers";
import { Link, useLocation } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/BorderColorOutlined";
import CloseIcon from "@mui/icons-material/Close";
import LinkIcon from "@mui/icons-material/OpenInNewOutlined";
import CopyIcon from "@mui/icons-material/ContentCopy";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import PersonIcon from "@mui/icons-material/Person";
import TelegramIcon from "../../assets/images/telegram-purple.svg";
import { socket } from "../../utils/socket";
// import TelegramSupportIcon from "../../assets/images/telegramSupport.svg";
import "animate.css";
import {
    COINHUB_TELEGRAM_URL,
    COINHUB_TWITTER_URL,
    IPFS_GATEWAY_URL,
    PULSECHAIN_SCAN_URL,
    SERVER_URL,
} from "../../abis/constants";
import TwitterIconWhite from "../../assets/images/twitter-logo_white.svg";
import WalletIcon from "../../assets/images/wallet-purple.svg";
import TwitterIcon from "../../assets/images/x-purple.svg";
import { useUserInfo } from "../../hooks/user";
import { uploadImageToIPFS } from "../../utils";
import Background from "../bgoverlay";
import ShakeBox from "../cards/shake";
import { CreatorAvatar, UserAvatar, UserName } from "../cards/user";
import { priceFormatter } from "../tvchart/chart";
import TokenLogo from "../tokenLogo";
//import { uploadImageToIPFS } from "../../utils";
import toast, { Toaster } from "react-hot-toast";
import { TimeDiff } from "../time";

import plsIcon from "../../assets/images/wpls.png";

const handleTwitterLogin = async (address: string) => {
    // axios.get(`${SERVER_URL}/fakelogin/${address}`)
    if (!address) {
        toast.error("Please connect your wallet first");
        return;
    }
    // socket.emit('TwitterLoginTry', {
    //     address: address,
    //     socketID: localStorage.getItem('socketID')
    // });
    try {
        window.open(
            `${SERVER_URL}/users/login/${address}`,
            "_blank",
            "width=600,height=700"
        );
    } catch (error) {
        console.error("Error initiating Twitter login", error);
    }
};

const options = ["Ethereum", "BSC"];

const Menu = styled(Box)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  align-items: stretch;
  padding: 1em;
  padding-top: 85px;
  backdrop-filter: blur(12px);
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  .MuiButton-root.MuiButton-colorPrimary {
    background-color: white;
    &:not(:disabled):hover {
      background-color: #fff8;
    }
  }
`;

const AvatarWrapper = styled(Box)`
  position: relative;
  display: inline-block;
  & > label {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;
const ConnectButton = styled(Button)`
  align-items: center;
  max-height: 40px;
  gap: 0.6em;
  background-color: white !important;
  border-radius: 16px;
  padding: 8px 24px;
  text-transform: none;
  box-shadow: none;
  &:hover {
    box-shadow: none;
    background: #fff8 !important;
  }
`;
const BridgeButton = styled(ConnectButton)`
  background-color: #292341 !important;
  &:hover {
    background: #29234188 !important;
  }
`;
const TwitterLoginButton = styled(Button)`
  align-items: center;
  gap: 1em;
  background-color: white !important;
  border-radius: 16px;
  padding: 8px 24px;
  text-transform: none;
  box-shadow: none;
  &:hover {
    box-shadow: none;
    background: #fff8 !important;
  }
`;

const LeaderboardButton = styled(Link)`
  text-decoration: none;
  align-items: stretch;
  color: white;
  // height: 40px;
  border-radius: 12px;
  background: linear-gradient(#292341, #292341) padding-box,
    linear-gradient(
        90deg,
        #ff8e08 0.14%,
        #fdffb3 11.87%,
        #ffed4c 23.15%,
        #fbe4a8 54.37%,
        #f8d185 74.22%,
        #e66606 79.32%,
        #fcf3a0 86.96%,
        #ff7629 100%
      )
      border-box;
  border: 2px solid transparent;
  color: white;
  font-family: "Riffic Free";
  padding: 8px 13px;
`;

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: "16px",
        position: "relative",
        backgroundColor: "#FFFFFF0F",
        border: "none",
        padding: "10px 20px",
    },
}));

const HiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1;
`;

const SocialLink = styled(Link)`
  background: white;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #fff8;
  }
`;

function ElevationScroll({
    children,
    window,
}: {
    children: React.ReactElement;
    window?: () => Window;
}) {
    // Use useScrollTrigger to respond to user scroll
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0, // Adjust elevation based on scroll. You can adjust this value.
        style: {
            transition: trigger ? "background-color 0.3s, box-shadow 0.3s" : "none", // Smooth transition for the background change
        },
    });
}

function LatestTrade({ item }: { item: any }) {
    return (
        <ShakeBox
            animate={item.id}
            p={{ xs: "9px", sm: "9px 2em" }}
            style={{
                background: item.type === "BUY" ? "#26a69a" : "#ef5350",
                borderRadius: "16px",
                textDecoration: "none",
                color: "white",
            }}
        >
            <Box
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <UserAvatar user={item.user} address={item.swapperAddress} size={25} />
                <Link
                    to={`/${item.network}/${item.tokenAddress}`}
                    style={{ textDecoration: "none" }}
                >
                    <Typography
                        sx={{ textWrap: "nowrap" }}
                        color="#E7E3D8"
                        fontSize="1.2em"
                    >
                        {item.type === "BUY" ? "Bought" : "Sold"}{" "}
                        {priceFormatter(item.tokenAmount, 2)} {item.tokenSymbol}
                    </Typography>
                </Link>
                <Link
                    to={`/${item.network}/${item.tokenAddress}`}
                    style={{ textDecoration: "none" }}
                >
                    {!IPFS_GATEWAY_URL ? (
                        <Avatar sx={{ width: 25, height: 25, ml: "0.5rem" }} />
                    ) : (
                        <TokenLogo
                            style={{ width: 25, height: 25, ml: "0.5rem" }}
                            logo={item.tokenImage}
                        />
                    )}
                </Link>
            </Box>
        </ShakeBox>
    );
}

function LatestToken({ item }: { item: any }) {
    return (
        <ShakeBox
            animate={item.id}
            p={{ xs: "9px", sm: "9px 2em" }}
            style={{
                background: "#433DFF",
                borderRadius: "16px",
                textDecoration: "none",
                color: "white",
            }}
        >
            <Box
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <CreatorAvatar token={item} size={25} />
                <Link
                    to={`/${item.network}/${item.tokenAddress}`}
                    style={{ textDecoration: "none" }}
                >
                    <Typography
                        sx={{ textWrap: "nowrap" }}
                        color="#E7E3D8"
                        fontSize="1.2em"
                    >
                        {" "}
                        Created {item.tokenSymbol}{" "}
                        <TimeDiff time={new Date(item.createdAt)} postfix="ago" />
                    </Typography>
                </Link>
                <Link
                    to={`/${item.network}/${item.tokenAddress}`}
                    style={{ textDecoration: "none" }}
                >
                    {!IPFS_GATEWAY_URL ? (
                        <Avatar sx={{ width: 25, height: 25, ml: "0.5rem" }} />
                    ) : (
                        <TokenLogo
                            style={{ width: 25, height: 25, ml: "0.5rem" }}
                            logo={item.tokenImage}
                        />
                    )}
                </Link>
            </Box>
        </ShakeBox>
    );
}

function Header({
    drawerWidth,
    elevation,
    onMenuOpen,
}: {
    drawerWidth: number;
    elevation?: number;
    onMenuOpen?: (isOpen: boolean) => void;
}) {
    const { open } = useWeb3Modal();
    const { address, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const { disconnect } = useDisconnect();
    const { userInfo, setUserInfo } = useUserInfo();
    // const { handleDrawerToggle } = useMainContext();
    const [isOpen, setIsOpen] = React.useState(false);
    const [modal, setModal] = React.useState<string>();
    const [userName, setUserName] = React.useState("");
    const [userBio, setUserBio] = React.useState("");
    const [avatar, setAvatar] = React.useState<any>();
    const [loading, setLoading] = React.useState<string>();
    const [latestTradeId, setLatestTradeId] = React.useState(0);
    const [latestTokenId, setLatestTokenId] = React.useState(0);
    const [latestTrade, setLatestTrade] = React.useState<any>();
    const [latestToken, setLatestToken] = React.useState<any>();
    const fileRef = React.useRef<any>();
    const [now, setNow] = useState(0);
    const { pathname } = useLocation();

    const referralLink = useMemo(
        () => `https://coin.quest/ref/${address?.slice(8, 16)}`,
        [address]
    );

    React.useEffect(() => {
        socket.on("connect", () => {
            console.log("mySocketID", socket.id);
            localStorage.setItem("socketID", socket.id!);
        });

        socket.on("message", (data: any) => {
            const res = JSON.parse(data);
            if (res.type === "twitter_login" && address === res.address) {
                setUserInfo(res.user);
            }
        });

        socket.on("disconnect", () => {
            console.log(socket.id); // undefined
            localStorage.removeItem("socketID");
        });

        return () => {
            socket.off("connect");
        };
    }, [address]);

    // useEffect(() => {
    //     const handleMessage = (event: any) => {
    //         //if (event.origin !== "http://localhost:5000") return;
    //         console.log('Received event:', event);

    //         // const { token, status } = event.data;

    //         // if (token) {
    //         //     setAccessToken(token);
    //         //     setLoginStatus(status);
    //         // }
    //     };

    //     window.addEventListener("message", handleMessage);

    //     return () => {
    //         window.removeEventListener("message", handleMessage);
    //     };
    // }, []);

    // const [latestAction, setLatestAction] = React.useState<string[]>([]);
    // let navigate = useNavigate();
    // const ownerAddress = "0xf3663B50babCe3B54b26743498b20079911F406f"; //owner wallet
    // const handleClick = () => {
    //     console.info(`You clicked ${options[selectedIndex]}`);
    // };

    // const handleMenuItemClick = (
    //     event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    //     index: number,
    // ) => {
    //     setSelectedIndex(index);
    //     setIsOpen(false);
    // };

    useEffect(() => {
        // socket.emit('NewAddressConnected', {
        //     address: address,
        //     socketID: localStorage.getItem('socketID')
        // });
    }, [address]);

    useEffect(() => {
        const intervalId = setInterval(() => setNow(Date.now()), 5000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        getLatestTrade().then(() => { });
    }, [now]);

    const handleToggle = () => {
        setIsOpen((prevOpen) => !prevOpen);
    };

    const handleAvatar = (e: any) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setAvatar(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const uploadLogo = async (img: any) => {
        let formData = new FormData(); // instantiate it
        // suppose you have your file ready
        formData.set("image", img);
        // add some data you collected from the input fields
        const r = axios.post(`${SERVER_URL}/tokens/upload`, formData, {
            headers: {
                "api-key": "hola",
                "content-type": "multipart/form-data", // do not forget this
            },
        });

        return r;
    };

    const handleSaveProfile = React.useCallback(async () => {
        try {
            setLoading("profile");
            const user: any = {
                userAddress: address,
                username: userName.length > 0 ? userName : " ",
                bio: userBio ?? " ",
            };
            if (avatar && fileRef.current.files?.length) {
                const img = fileRef.current.files[0];
                const extension = img.name.split(".").pop();
                if (
                    extension &&
                    !["jpg", "jpeg", "png"].includes(extension.toLowerCase())
                ) {
                    toast.error("Only jpg, jpeg, png files are allowed");
                    setLoading(undefined);
                    return;
                }
                // get file size
                if (img.size > 1024 * 1024) {
                    toast.error("File size should be less than 1MB");
                    setLoading(undefined);
                    return;
                }

                ///const ipfsResult = await uploadImageToIPFS(fileRef.current.files[0])
                const logoUploadResult = await uploadLogo(img);
                console.log(logoUploadResult);
                user.avatar = logoUploadResult.data.file.filename;
                //user.avatar = ipfsResult?.IpfsHash
            }
            // update userInfo
            const refCode = localStorage.getItem("referral");
            const provider = new BrowserProvider(walletProvider as Eip1193Provider);
            const signer = await provider.getSigner();
            const msg = Buffer.from(ethers.randomBytes(24)).toString("hex");
            const signature = await signer.signMessage(msg);
            await axios
                .post(`${SERVER_URL}/users${userInfo?.id ? "/update" : ""}`, {
                    user,
                    signature,
                    msg,
                    refCode,
                })
                .then(({ data }) => {
                    if (data) {
                        setUserInfo(data);
                        setModal(undefined);
                        localStorage.removeItem("referral");
                    }
                });
        } catch (err: any) {
            console.error(err.response.data.error);
            toast.error(err.response.data.error);
        }
        setLoading(undefined);
    }, [fileRef, address, userName, userBio, avatar, userInfo, walletProvider]);

    // const handleClose = (event: Event) => {
    //     if (
    //         anchorRef.current &&
    //         anchorRef.current.contains(event.target as HTMLElement)
    //     ) {
    //         return;
    //     }

    //     setIsOpen(false);
    // };

    const sleep = async (ms: number) =>
        new Promise((resolve) => setTimeout(resolve, ms));

    const getLatestTrade = useCallback(async () => {
        try {
            await axios
                .get(SERVER_URL, {
                    params: {
                        latestTradeId,
                        latestTokenId,
                    },
                })
                .then(function (response) {
                    const { trades, tokens } = response.data;
                    if (trades.length) {
                        setLatestTradeId(trades[0].id);
                        setTimeout(async () => {
                            const interval = Math.min(Math.floor(5000 / trades.length), 500);
                            for (const trade of trades.reverse()) {
                                setLatestTrade(trade);
                                await sleep(interval);
                            }
                        }, 10);
                    }
                    if (tokens.length) {
                        setLatestTokenId(tokens[0].id);
                        setTimeout(async () => {
                            const interval = Math.min(Math.floor(5000 / tokens.length), 500);
                            for (const token of tokens.reverse()) {
                                setLatestToken(token);
                                await sleep(interval);
                            }
                        }, 10);
                    }
                })
                .catch(function (error) {
                    // console.log(error);
                });
        } catch (error) { }
    }, [latestTradeId, latestTokenId]);

    React.useEffect(() => {
        socket.on("message", (data: any) => {
            getLatestTrade();
        });

        return () => {
            socket.off("message");
        };
    }, []);

    React.useEffect(() => {
        setIsOpen(false);
        // setUserInfo(undefined)
        console.log("switch", address);
        if (isConnected) {
            axios
                .get(`${SERVER_URL}/users`, {
                    params: {
                        userAddress: address,
                    },
                })
                .then(({ data }) => {
                    setUserInfo(data);
                    setUserName(data.username);
                    setUserBio(data.bio);
                })
                .catch(() => {
                    setUserInfo(undefined);
                    setUserName("");
                    setUserBio("");
                    setModal("profile");
                });
        }
    }, [isConnected, address]);

    useEffect(() => {
        onMenuOpen?.(isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (modal) {
            setIsOpen(false);
        }
    }, [modal]);

    useEffect(() => {
        setIsOpen(false);
    }, [pathname]);

    useEffect(() => {
        // get isFirstTime from localstorage
        const isFirstTime = localStorage.getItem("isFirstTime");
        // if it is first time, show the dialog
        if (isFirstTime === null) {
            setModal("how");
            // set isFirstTime to false
            localStorage.setItem("isFirstTime", "false");
        }
    }, []);

    return (
        <>
            {/* <ElevationScroll> */}
            <AppBar
                position="absolute"
                // position={window.innerWidth > 600 ? "fixed" : "absolute"}
                sx={{
                    //     width: { sm: `calc(100 % - ${drawerWidth}px)` },
                    //     ml: { sm: `${drawerWidth}px` },
                    background: "none",
                    boxShadow: "none",
                    zIndex: 900,
                    //     // backdropFilter: isOpen ? 'none' : 'blur(10px)',
                }}
            >
                {/* {
                    isOpen &&
                    <Background />
                } */}
                <Toolbar
                    sx={{
                        padding: { md: "10px 32px", xs: "24px 16px" },
                        display: "flex",
                        flexDirection: "row",
                        gap: "24px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minHeight: "fit-content",
                    }}
                >
                    {
                        <IconButton
                            color="inherit"
                            onClick={handleToggle}
                            sx={{
                                p: "8px",
                                borderRadius: "12px",
                                display: { sm: "none" },
                                bgcolor: "white",
                            }}
                        >
                            {isOpen ? (
                                <CloseIcon htmlColor="#51419E" />
                            ) : (
                                <MenuIcon htmlColor="#51419E" />
                            )}
                        </IconButton>
                    }
                    {!isOpen && (
                        <Box
                            alignItems="center"
                            gap="8px"
                            sx={{ whiteSpace: "nowrap", display: "flex" }}
                            justifyContent="space-between"
                        >
                            <Box display="flex" gap="8px">
                                <SocialLink to={COINHUB_TELEGRAM_URL} target="_blank">
                                    <img
                                        src={TelegramIcon}
                                        width={30}
                                        height={30}
                                        alt="telegramCommunity"
                                    />
                                </SocialLink>
                                <SocialLink to={COINHUB_TWITTER_URL} target="_blank">
                                    <img src={TwitterIcon} width={20} height={20} alt="twitter" />
                                </SocialLink>
                            </Box>
                            <Box sx={{ display: { sm: "inline", xs: "none" }, ml: 4 }}>
                                <Typography
                                    sx={{
                                        color: "#51419E",
                                        cursor: "pointer",
                                        "&:hover": { opacity: 0.8 },
                                    }}
                                    fontSize="14px"
                                    onClick={() => setModal("how")}
                                >
                                    How it works?
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: { sm: "inline-flex", xs: "none" },
                                    alignItems: "center",
                                    gap: "8px",
                                }}
                            >
                                <LeaderboardButton
                                    to="/leaderboard"
                                    style={{ marginLeft: "20px", fontSize: "16px" }}
                                >
                                    Leaderboard
                                </LeaderboardButton>
                                {!!userInfo && (
                                    <Link to="/profile/me/referral">
                                        <ConnectButton>
                                            <Typography color="#51419E" whiteSpace="nowrap">
                                                Referral code
                                            </Typography>
                                        </ConnectButton>
                                    </Link>
                                )}
                            </Box>
                            {/* {
                                isConnected &&
                                <Box sx={{ display: { sm: "none", xs: "inline" } }}>
                                    <ConnectButton style={{ borderRadius: '12px', padding: '10px' }}>
                                        <UserAvatar user={userInfo} address={address} me size={32} />
                                        <UserName user={userInfo} address={address} me fontSize="12px" color="#51419E" />
                                        <SettingsIcon sx={{ color: "#51419E" }} onClick={() => setModal("info")} />
                                    </ConnectButton>
                                </Box>
                            } */}
                        </Box>
                    )}
                    {!isOpen && (
                        <Link
                            className="site-logo"
                            style={{
                                padding: 0,
                                position: "absolute",
                                left: "50%",
                                top: "10px",
                                transform: "translate(-50%, 0)",
                            }}
                            to="/"
                        >
                            <img src={Logo} alt="logo" />
                        </Link>
                    )}
                    <Box
                        my="-4px"
                        display={{ sm: "flex", xs: "none" }}
                        gap="8px"
                        alignItems="center"
                    >
                        <Link
                            to="https://bridge.pulsechain.com/"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                        >
                            <BridgeButton
                                style={{
                                    fontSize: "16px",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                }}
                            >
                                <Avatar src={plsIcon} sx={{ width: 24, height: 24 }} />
                                <Typography color="white" whiteSpace="nowrap">
                                    Bridge to PulseChain
                                </Typography>
                            </BridgeButton>
                        </Link>
                        {!isConnected ? (
                            <>
                                <ConnectButton onClick={() => open()}>
                                    <img src={WalletIcon} width={24} height={24} alt="connect" />
                                    <Typography color="#51419E" whiteSpace="nowrap">
                                        Connect Wallet
                                    </Typography>
                                </ConnectButton>
                                {/* <TwitterLogin
                                        authCallback={authHandler}
                                        consumerKey={"Cj0u67mgvGChrSqizRk4snXgV"}
                                        consumerSecret={"oKEZsY8w7Mk0OBUCZ6dTvpxhlLygIyHTbJmJeQJCIxKXp71OWg"}
                                    /> */}
                                {/* <ConnectButton onClick={() => handleTwitterLogin(address!)}>
                                        <img src={TwitterIcon} width={24} height={24} alt="connect" />
                                        <Typography color="#51419E" whiteSpace="nowrap">
                                            Login
                                        </Typography>
                                    </ConnectButton> */}
                            </>
                        ) : (
                            <>
                                <ConnectButton>
                                    <UserAvatar
                                        user={userInfo}
                                        address={address}
                                        me
                                        size={32}
                                        showAdmin
                                    />
                                    <UserName
                                        user={userInfo}
                                        address={address}
                                        me
                                        fontSize="12px"
                                        color="#51419E"
                                    />
                                    <SettingsIcon
                                        sx={{ color: "#51419E" }}
                                        onClick={() => setModal("info")}
                                    />
                                </ConnectButton>
                                {!userInfo?.twitter_username && (
                                    <ConnectButton onClick={() => handleTwitterLogin(address!)}>
                                        <img
                                            src={TwitterIcon}
                                            width={24}
                                            height={24}
                                            alt="connect"
                                        />
                                        <Typography color="#51419E" whiteSpace="nowrap">
                                            Login
                                        </Typography>
                                    </ConnectButton>
                                )}
                            </>
                        )}
                    </Box>
                    {/* {
                            address?.toLowerCase() == ownerAddress?.toLowerCase() &&
                            <Box display={"flex"}>
                                <Button variant="contained" style={{ alignItems: 'center', gap: '1em', color: 'white', background: "linear-gradient(90deg, #6928d5 14.14%, #9E1F63 100%)", borderRadius: 16, padding: '8px 24px', textTransform: 'none' }} onClick={() => navigate('/admin')}>Admin</Button>
                            </Box>
                        } */}
                </Toolbar>
                {!isOpen && (
                    <Box
                        flexDirection={{ sm: "row", xs: "column" }}
                        justifyContent="center"
                        sx={{
                            overflow: "hidden",
                            display: "flex",
                            gap: "8px",
                            borderRadius: "10px",
                            mt: {
                                xs: "74px",
                                sm: "96px",
                                md: "124px",
                                lg: "125px",
                                xl: "64px",
                            },
                            px: { xs: "36px", sm: "36px", md: "52px", lg: "220px" },
                        }}
                        alignItems="stretch"
                    >
                        {!!latestTrade && <LatestTrade item={latestTrade} />}
                        {!!latestToken && <LatestToken item={latestToken} />}
                    </Box>
                )}
                {isOpen && (
                    <Menu sx={{ display: { sm: "none", xs: "flex" } }}>
                        {!isConnected && (
                            <ConnectButton
                                style={{ borderRadius: 16 }}
                                onClick={() => open()}
                            >
                                <img src={WalletIcon} width={24} height={24} alt="connect" />
                                <Typography color="#51419E" whiteSpace="nowrap">
                                    Connect Wallet
                                </Typography>
                            </ConnectButton>
                        )}
                        {isConnected && (
                            <>

                                <ConnectButton style={{ borderRadius: 16 }} onClick={() => { }}>
                                    <UserAvatar
                                        user={userInfo}
                                        address={address}
                                        me
                                        size={32}
                                        showAdmin
                                    />
                                    <UserName
                                        user={userInfo}
                                        address={address}
                                        me
                                        fontSize="12px"
                                        color="#51419E"
                                    />
                                    <SettingsIcon
                                        sx={{ color: "#51419E" }}
                                        onClick={() => setModal("info")}
                                    />
                                </ConnectButton>

                                {/* <ConnectButton
                                    style={{ borderRadius: 16 }}
                                    onClick={() => setModal("info")}
                                >
                                    <SettingsIcon sx={{ color: "#51419E" }} />
                                    <Typography sx={{ color: "#51419E" }}>Settings</Typography>
                                </ConnectButton> */}
                                <ConnectButton
                                    style={{ borderRadius: 16 }}
                                    onClick={() => disconnect()}
                                >
                                    <img src={WalletIcon} width={24} height={24} alt="connect" />
                                    <Typography color="#51419E" whiteSpace="nowrap">
                                        Disconnect
                                    </Typography>
                                </ConnectButton>
                            </>
                        )}
                        <LeaderboardButton
                            to="/leaderboard"
                            style={{
                                borderRadius: "16px",
                                textAlign: "center",
                                fontSize: "1rem",
                                padding: "10px",
                            }}
                        >
                            Leaderboard
                        </LeaderboardButton>
                        {!!userInfo && (
                            <Link to="/profile/me/referral">
                                <ConnectButton style={{ width: "100%" }}>
                                    <Typography color="#51419E" whiteSpace="nowrap">
                                        Referral code
                                    </Typography>
                                </ConnectButton>
                            </Link>
                        )}
                        <Link
                            to="https://bridge.pulsechain.com/"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                        >
                            <BridgeButton
                                style={{
                                    fontSize: "16px",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                    width: "100%",
                                }}
                            >
                                <Avatar src={plsIcon} sx={{ width: 24, height: 24 }} />
                                <Typography color="white" whiteSpace="nowrap">
                                    Bridge to PulseChain
                                </Typography>
                            </BridgeButton>
                        </Link>
                        <ConnectButton
                            style={{ borderRadius: 16 }}
                            onClick={() => setModal("how")}
                        >
                            <Typography color="#51419E" whiteSpace="nowrap">
                                How it works?
                            </Typography>
                        </ConnectButton>
                    </Menu>
                )}
            </AppBar>
            {/* </ElevationScroll> */}
            <Dialog
                open={modal === "info"}
                fullWidth
                // TransitionComponent={Transition}
                keepMounted
                onClose={() => setModal(undefined)}
            >
                <DialogTitle>
                    <UserName
                        user={userInfo}
                        address={address}
                        fontSize="inherit"
                        color="white"
                        fontFamily="Riffic Free"
                    />
                    <IconButton onClick={() => setModal(undefined)}>
                        <CloseIcon sx={{ color: "#9E9E9E" }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <UserAvatar user={userInfo} address={address} size={94} />
                    <Typography color="#E7E3D8" fontSize="14px">
                        {userInfo?.bio}
                    </Typography>
                    <div>
                        <Typography color="#E7E3D8" fontSize="14px">
                            Pulse address
                        </Typography>
                        <Link
                            to={`${PULSECHAIN_SCAN_URL}/address/${address}`}
                            target="_blank"
                            style={{
                                textDecoration: "none",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Typography
                                color="white"
                                style={{
                                    overflowWrap: "break-word",
                                    maxWidth: "calc(100% - 30px)",
                                }}
                            >
                                {address}
                            </Typography>
                            <LinkIcon sx={{ color: "white" }} />
                        </Link>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setUserName("");
                            setUserBio("");
                            setAvatar(undefined);
                            setModal("profile");
                        }}
                        color="secondary"
                        fullWidth
                    >
                        Edit profile
                    </Button>
                    <Button
                        onClick={() => {
                            disconnect();
                            setModal(undefined);
                        }}
                        fullWidth
                    >
                        Disconnect
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={modal === "profile"}
                fullWidth
                // TransitionComponent={Transition}
                keepMounted
                onClose={loading === "profile" ? () => { } : () => setModal(undefined)}
            >
                <DialogTitle>
                    {userInfo ? "Edit" : "Create"} profile
                    {loading !== "profile" && (
                        <IconButton onClick={() => setModal(undefined)}>
                            <CloseIcon sx={{ color: "#9E9E9E" }} />
                        </IconButton>
                    )}
                </DialogTitle>
                <DialogContent>
                    <div>
                        <AvatarWrapper>
                            {avatar ? (
                                <Avatar sx={{ width: 94, height: 94 }} src={avatar} />
                            ) : (
                                <UserAvatar size={94} user={userInfo} address={address} />
                            )}
                            <IconButton
                                sx={{ bgcolor: "#E12D85", "&:hover": { bgcolor: "#E12D8588" } }}
                                component="label"
                            >
                                <EditIcon sx={{ width: 16, height: 16 }} />
                                <HiddenInput
                                    ref={fileRef}
                                    type="file"
                                    onChange={handleAvatar}
                                />
                            </IconButton>
                        </AvatarWrapper>
                    </div>
                    <Typography color="#E7E3D8" fontSize="14px">
                        You can change your username once every day
                    </Typography>
                    <FormControl variant="standard">
                        <InputLabel shrink>Username</InputLabel>
                        <BootstrapInput
                            fullWidth
                            placeholder="Your name is here"
                            value={userName ?? userInfo?.username ?? ""}
                            onChange={(e: any) => setUserName(e.target.value)}
                        />
                    </FormControl>
                    <FormControl variant="standard">
                        <InputLabel shrink>Bio</InputLabel>
                        <BootstrapInput
                            fullWidth
                            placeholder="Your bio is here"
                            value={userBio ?? userInfo?.bio ?? ""}
                            onChange={(e: any) => setUserBio(e.target.value)}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setModal(undefined)}
                        color="secondary"
                        fullWidth
                        disabled={loading === "profile"}
                    >
                        Close
                    </Button>
                    <Button
                        onClick={handleSaveProfile}
                        fullWidth
                        disabled={loading === "profile"}
                        endIcon={
                            loading === "profile" ? (
                                <CircularProgress color="inherit" size={14} />
                            ) : undefined
                        }
                    >
                        {loading === "profile" ? "Saving" : "Save"}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={modal === "how"}
                fullWidth
                // TransitionComponent={Transition}
                keepMounted
                onClose={() => setModal(undefined)}
            >
                <DialogTitle>
                    How it works
                    <IconButton onClick={() => setModal(undefined)}>
                        <CloseIcon sx={{ color: "#9E9E9E" }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography mb="1rem">
                        CoinQuest prevents rugs by making sure that all created tokens are
                        safe. Each coin on CoinQuest is a{" "}
                        <span style={{ fontWeight: "bold", color: "#87EEAB" }}>
                            fair-launch
                        </span>{" "}
                        with{" "}
                        <span style={{ fontWeight: "bold", color: "#92C5FD" }}>
                            no presale
                        </span>{" "}
                        and{" "}
                        <span style={{ fontWeight: "bold", color: "#fdba74" }}>
                            no team allocation
                        </span>
                        .
                    </Typography>
                    <Typography>step 1: Pick a coin that you like</Typography>
                    <Typography>step 2: Buy the coin on the bonding curve</Typography>
                    <Typography>
                        step 3: Sell at any time to lock in your profits or losses
                    </Typography>
                    <Typography>
                        step 4: When enough people buy on the bonding curve it reaches a
                        market cap of $36.9k
                    </Typography>
                    <Typography>
                        step 5: The LP is then deposited on a dex and burned forever
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setModal(undefined)}
                        color="secondary"
                        fullWidth
                    >
                        Close
                    </Button>
                    <Link to="/" style={{ width: "100%" }}>
                        <Button
                            onClick={() => {
                                setModal(undefined);
                            }}
                            fullWidth
                        >
                            <span style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                                Start the quest!
                            </span>
                        </Button>
                    </Link>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Header;
