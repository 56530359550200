import { Box, styled } from "@mui/material"

const PageBox = styled(Box)`
  margin-top: -20px;
  position: relative;
  // background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  // border-radius: 48px 48px 0 0;
  // height: 480px;
  padding: 16px;
  box-sizing: border-box;
  // & > :not(img.token-banner) {
  //   z-index: 1;
  // }
  ${props => props.theme.breakpoints.down("md")} {
    border-radius: 32px;
    padding: 8px;
  }
  ${props => props.theme.breakpoints.down("sm")} {
    border-radius: 24px;
    padding: 0px;
  }
`

export default PageBox